import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormGroup,
  TextField,
  Divider,
  Typography
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "@material-ui/lab";
import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { generateRandomString, generateExtraData, makeRequest } from "../../../utils/utils";
import * as yup from "yup";
import * as settingsActions from "../../../actions/settingsActions";
import { API_ENDPOINT, ERROR_MESSAGE, METHOD_TYPES, RE_CAPTCHA_SECRET_KEY } from "../../../utils/constants";
import useStyles from "./LoginStyles";
import GoogleAuth from "../SocialLogin/GoogleAuthentication/GoogleAuth";
// import FacebookAuth from "../SocialLogin/FacebookAuthentication/FacebookAuth";
import IconButton from '@material-ui/core/IconButton';
import withLoader from "../../../components/hoc/withLoader";
// import * as roomActions from "../../../actions/roomActions";
// import { withRoomContext } from "../../../RoomContext";
import { sendAnalyticsEvent, GTM_EVENT, GTM_METHODS } from "../../../utils/gtmUtils";

const schema = yup.object().shape({
  email: yup
    .string()
    .required(ERROR_MESSAGE.EMAIL.REQUIRED)
    .email(ERROR_MESSAGE.EMAIL.INVALID),
  password: yup.string().required(ERROR_MESSAGE.PASSWORD.REQUIRED)
});

const Login = ({setLoadWithoutMount,
  handleClickOpenPopup, 
  handleClickClosePopup,
  roomId
  // roomClient,
  // room,
  // roomId,
  // displayName,
  // changeDisplayName
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const invalidAttempts = useSelector(state => state.settings.invalidAttempts);
  const ipInfo = useSelector(state => state.settings.ipInfo);
 // const smallScreen = useMediaQuery(LoginFormtheme.breakpoints.down("xs"));
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [showResendLink, setShowResendLink] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);
//  const [isInternetConnected, setIsInternetConnected] = useState(true);
  let roomNameId =  useSelector(state => state?.settings?.userData?.roomId) || generateRandomString();
  roomNameId = roomId || roomNameId

    // Edge 20+
// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;

  const togglePassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  // useEffect(() => {
  //   const handleOnline = () => {
  //     setIsInternetConnected(true);
  //   };

  //   const handleOffline = () => {
  //     setIsInternetConnected(false);
  //   };

  //   if (navigator.onLine) {
  //     handleOnline();
  //   } else {
  //     handleOffline();
  //   }

  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);

  const onSubmit = async (payload) => {
    sendAnalyticsEvent(GTM_EVENT.SIGN_IN, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, "Manual")
    setErrorMessage(null);
    setShowResendLink(false);
    setLoadWithoutMount(true);
    const result = await makeRequest(
      METHOD_TYPES.POST,
      API_ENDPOINT.LOGIN,
      payload
    );
    if (result.statusCode === 200) {
      const { userId, firstName, lastName = "", email, birthYear, token, avatar, registrationMethod } =
        result.data || {};
      dispatch(settingsActions.setUserId(userId));
      dispatch(
        settingsActions.setUserData({
          firstName,
          lastName,
          email,
          birthYear,
          token,
          avatar,
          roomId: roomNameId,
          isGuest: false,
          registrationMethod
        })
      );
      handleClickClosePopup()
      // join room direct from login
      // if(roomClient){
      // const systemInfo = generateExtraData();
      // const extraData = {
      //   ipInfo,
      //   systemInfo,
      //   isGuest: false,
      // };
      // const displayNameChange = displayName || `${firstName} ${lastName || ""}`
      // changeDisplayName(displayNameChange);

      // await roomClient.join({
      //   roomId: roomNameId,
      //   joinVideo: false,
      //   peerId: generateRandomString(),
      //   extraData
      // });
      // }
      history.push(`/${roomNameId}`);
    //  history.push(`/dashboard`); // dashboard link chnage 
    } else {
      setErrorMessage({ message: result.message, severity: 'error' });
      if(result.statusCode === 400) {
        dispatch(settingsActions.invalidAttempts(invalidAttempts + 1));
      }
      if (invalidAttempts >= 4) {
        setShowCaptcha(true);
      }
      if (result.statusCode === 400 && result?.data?.isVerified === false) {
        setShowResendLink(true);
      }
    }
    setLoadWithoutMount(false);
  };

  const handleResendVerification = async () => {
    setLoadWithoutMount(true);
    try {
      const payload = {
        email: getValues("email"),
      };
      const response = await makeRequest(
        METHOD_TYPES.POST,
        API_ENDPOINT.RESEND_VERIFICATION,
        payload
      );
      if (response.statusCode === 200) {
        setErrorMessage({ message: response.message, severity: "success" });
        setShowResendLink(false);
      } else {
        setErrorMessage({
          message: response.message,
          severity: "error",
        });
      }
    setLoadWithoutMount(false);
    } catch (error) {
      console.error(error);
      setLoadWithoutMount(false);
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaChecked(true);
    }
  };

  const handleCaptchaExpired = () => {
    setCaptchaChecked(false);
  };

  // const handleFacebookLogin = useCallback(async (response) => {
  //   const userData = {
  //     firstName: response.name.split(" ")[0],
  //     lastName: response.name.split(" ")[1] || "",
  //     email: response.email
  //   };
  //   await handleAuthentication("facebook", userData);
  // }, []);
  
  const handleAuthentication = useCallback(async (provider, userData) => {
    setErrorMessage(null);
    try {
      const systemInfo = generateExtraData();
      const extraData = {
        ipInfo,
        systemInfo,
        isGuest: false,
      };
      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        registrationMethod: provider,
        extraData
      };
  
      const result = await makeRequest(
        METHOD_TYPES.POST,
        API_ENDPOINT.SOCAIL_AUTH,
        payload
      );
  
      if (result.statusCode === 200) {
        const { userId, firstName, lastName = "", email, birthYear, token, avatar, registrationMethod } = result.data || {};
        dispatch(settingsActions.setUserId(userId));
        dispatch(settingsActions.setUserData({
          firstName,
          lastName,
          email,
          birthYear,
          token,
          avatar,
          roomId: roomNameId,
          isGuest: false,
          registrationMethod
        }));
        handleClickClosePopup()
         history.push(`/${roomNameId}`);
       // history.push(`/`); // dashboard link chnage
      } else {
        const { userId = null, birthYear = null } = result.data || {};
        setErrorMessage({ message: result.message, severity: 'error' });
        if (!birthYear && !!userId) {
          dispatch(settingsActions.setUserId(userId));
          history.push('/birth-year');
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, roomNameId, history, ipInfo, handleClickClosePopup]);

  const handleGoogleLogin = useCallback(async (response) => {
    sendAnalyticsEvent(GTM_EVENT.SIGN_IN, GTM_METHODS.BUTTON, GTM_METHODS.CLICK, 'Google')
    const userData = {
      firstName: response.given_name,
      lastName: response.family_name,
      email: response.email
    };
    await handleAuthentication("google", userData);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
       {!!errorMessage && (
          <Box mb={2} className="animate-box">
            <Alert
              className={classes.iconBtn}
              severity={errorMessage.severity}
              onClose={() => setErrorMessage(null)}
            >
              {errorMessage.message}
              
              {showResendLink && (
              <Link onClick={handleResendVerification} className={`${classes.resendLink} ${classes.link}`}>
               Resend Email
              </Link>
              )}
            </Alert>
          </Box>
        )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <FormGroup fullWidth>           
            <TextField
              id="email"
              placeholder="Enter Email *"
              className={classes.inputBorder}
              variant="outlined"
              name="email"
              type="email"
              {...register("email")}
              error={!!errors.email}
            />
          </FormGroup>
          {errors.email && (
            <p className={classes.error}>{errors.email.message}</p>
          )}
        </Box>
        <Box mb={2} position={"relative"}>
          <FormGroup fullWidth>
              <TextField
                id="password"
                placeholder="Enter Password *"
                className={`${classes.inputBorder} ${classes.adornmentIcon}`}
                variant="outlined"
                name="password"
                type={passwordType}
                {...register("password")}
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    !!!isEdge && (
                          <IconButton onClick={togglePassword} edge="end"
                           >
                              {passwordType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                    )
                  )
              }}
              />
            {errors.password && (
              <p className={classes.error}>{errors.password.message}</p>
            )}
          </FormGroup>
        </Box>
        {showCaptcha && (
          <Box mb={2} ml={1} className={classes.responsiveCaptcha} >
            <ReCAPTCHA
              sitekey={RE_CAPTCHA_SECRET_KEY}
              onChange={handleCaptchaChange}
              onExpired={handleCaptchaExpired}
            />
            {!captchaChecked && (
              <p className={classes.error} style={{margin: "4px 6px 0"}}>Please verify the captcha.</p>
            )}
          </Box>
        )}
       <Box mb={4} display="flex" justifyContent="space-between" >
         <Link  className={classes.link} to="" onClick={() => handleClickOpenPopup('joinRoom', 'Join as a guest')}>
            Join as a guest
          </Link>
        <Link className={classes.link} to="" onClick={() => handleClickOpenPopup('forgetPassword', 'Forgot password')}>
            Forgot password
          </Link>
        </Box>
        <Box mb={3}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={!isDirty || !isValid || (showCaptcha && !captchaChecked )}
          className={classes.loginBtn}
        >
          Sign in
        </Button>
        </Box>
      </form>
      <Box mb={3} textAlign="center">
      <Link className={classes.link} to="" onClick={() => handleClickOpenPopup('register', 'Create your account')}>
           Don't have an account?
          </Link>
      </Box>
        <Box  mb={3} display="flex" alignItems="center" justifyContent="space-between" width="100%">
         <Divider style={{flex: '1'}} />
          <Typography style={{ marginInline: 10 }}>OR</Typography>
            <Divider style={{flex: '1'}} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" >
          <GoogleAuth btnText="Sign in with Google" onSuccess={handleGoogleLogin} />
          {/* <FacebookAuth onSuccess={handleFacebookLogin} /> */}
        </Box>
    </Box>
  );
};

 export default withLoader(Login);

// const mapDispatchToProps = (dispatch) => {
//   return {
//     changeDisplayName: (displayName) => {
//       dispatch(settingsActions.setDisplayName(displayName.trim()));
//     },
//     setJoiningRoom: (value) => {
//       dispatch(roomActions.setJoiningRoom(value));
//     },
//   };
// };

// const mapStateToProps = (state) => {
//   return {
//     room: state.room,
//     displayName: state.settings.displayName,
//     displayNameInProgress: state.me.displayNameInProgress,
//     loginEnabled: state.me.loginEnabled,
//     loggedIn: state.me.loggedIn,
//     myPicture: state.me.picture,
//     joiningRoom: state.room.joiningRoom,
//   };
// };

// export default withRoomContext(
//   connect(mapStateToProps, mapDispatchToProps, null, {
//     areStatesEqual: (next, prev) => {
//       return (
//         prev.room.inLobby === next.room.inLobby &&
//         prev.room.signInRequired === next.room.signInRequired &&
//         prev.settings.displayName === next.settings.displayName &&
//         prev.me.displayNameInProgress === next.me.displayNameInProgress &&
//         prev.me.loginEnabled === next.me.loginEnabled &&
//         prev.me.loggedIn === next.me.loggedIn &&
//         prev.me.picture === next.me.picture &&
//         prev.room.joiningRoom === next.room.joiningRoom
//       );
//     },
//   })((withLoader(Login)))
// );

